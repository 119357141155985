import { mapGetters, mapState } from 'vuex';

import {
  canAdd,
  canDelete,
  insufficientPermissionsMessage,
} from '../../../shared/helpers/permissionsUtils';
import KnLocalAlert from '../../../shared/components/KnLocalAlert.vue';
import KnTableSubjects from '../../components/KnTableSubjects/KnTableSubjects.vue';


export default {
  name: 'SubjectsIndex',
  components: {
    KnLocalAlert,
    KnTableSubjects,
  },
  props: {},
  data() {
    return {
      // headers: [
      //   { text: "Materia", value: "materia.nombre" },
      //   { text: "Fecha de Asignación", value: "fecha_asignacion" },
      //   { text: "Profesor", value: "profesor.datos_personales" },
      //   { text: "Porcentaje Actual", value: "currentPercentage", sortable: false },
      // ],
      // items: [],
      loading: false,
      /** Variables para alerta */
      errors: [],
      warnings: [],
      loadingAlert: false,
      showAlert: false,
      alertType: 'info',
      alertText: insufficientPermissionsMessage(),
      alertColor: 'warning',
      /*********************** */
    };
  },
  computed: {
    ...mapState(['institutionId', 'userData', 'search', 'currentPage']),
    ...mapGetters(['isTeacherEmploye', 'employeeId']),
  },

  
  methods: {
    canAdd: canAdd,
    canDelete: canDelete,
    
    

    // async action2(value) {
    //   try {
    //     let response;
    //     this.showInactive
    //       ? (response = await fetchReactivateSubject(value))
    //       : (response = await fetchRemoveSubject(value));

    //     if (response.ok) {
    //       await this.getSubjects(!this.showInactive);
    //     }
    //   } catch (error) {
    //     console.error('Error processing the request:', error);
    //   }
    // },
    // async changePageSubjects(page) {
    //   this.setPaginationPage(page);
    //   await this.getSubjects();
    // },
    // async actionInactive(value) {
    //   this.resetPagination();
    //   await this.getSubjects(!value);
    //   this.showInactive = value;
    // },
  },
};
